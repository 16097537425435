import React from 'react'
import { Link  } from "gatsby"
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'

export default function Article({ post, preview }) {
    let fullHtml = `<p><b>${post.frontmatter.location} (${post.frontmatter.date})</b> – `
    let readMoreLink = ``
    let image = ``
    // Previews show the excerpt
    if (preview) {
        fullHtml += post.excerpt
        readMoreLink = (
            <Link to={post.fields.slug}>
                 Read More »
            </Link>
        )
    }
    else {
        fullHtml += post.html.slice(3)  // slice to get rid of <p> tag
        if(post.frontmatter.image) {
            let imgWidth = post.frontmatter.imageSize ? post.frontmatter.imageSize : "100%"
            image = (
                <img src={ "/" + post.frontmatter.image} alt={post.frontmatter.image} style={{width: imgWidth}}/>
            )
        }
    }

    return (
        <article key={post.id} className="mb-5 w-100">
            <small className="text-secondary">
                <FontAwesomeIcon className="mr-1" icon={faClock} />
                {post.frontmatter.date}
            </small>
            <Link to={post.fields.slug}>
                <h5 className="mt-2">{post.frontmatter.title}</h5>
            </Link>
            <div dangerouslySetInnerHTML={{ __html: fullHtml  }} />
            { readMoreLink }
            { image }
        </article>
    )
}

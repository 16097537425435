import React from "react"
import { Container, Row, Col  } from 'react-bootstrap'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Archives from "../components/archives"
import Article from "../components/article"

export default function NewsPost({ pageContext, data }) {
    const { uniqueDates, countPosts } = pageContext
    const post = data.news

    return (
        <Layout>
            <Seo title="News" />
            <Container>
                <Row className="justify-content-between">
                    <Col lg={8}>
                        <Article post={post} preview={false} />
                    </Col>
                    <Col lg={3}>
                        <Archives
                            countPosts={countPosts}
                            uniqueDates={ uniqueDates }
                        />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

// Get the post with the exact slug
export const query = graphql`
    query NewsQuery($slug: String!) {
        news: markdownRemark(fields: { slug: { eq: $slug  }  }) {
            fields {
                slug
            }
            frontmatter {
                date(formatString: "LL")
                title
                location
                image
                imageSize
            }
            html
            id
        }
    }
`

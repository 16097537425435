import React from 'react'
import { Link  } from "gatsby"

export default function Archives({ uniqueDates, countPosts }) {
    let dateLinks = []
    for(let date of uniqueDates) {
        dateLinks.push (
            <Link className="mb-2" key={`/news/filter/${date.date}`}
                  to={`/news/filter/${date.date}`}>{date.niceDate} ({countPosts[date.date]})</Link>
        )
    }

    return (
        <div className="d-flex flex-column">
            <h4>Archives</h4>
            { dateLinks }
        </div>
    )
}
